import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Notes App Template | Notes App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/notes-mobile-app/"
    metaDescription=" Looking to design your own sticky notes app for mobile? Our premade design template is here to streamline your design flow. Try now with Uizard Pro"
    description="
    h2:Our sticky notes mobile app design template is here!
    <br/>
    Looking to design your own notes app? Our brand-new template is here to streamline your design flow. Our <a:https://uizard.io/templates/>UI design templates</a> are built in house by design experts, meaning that they come fully equipped with everything you could possibly need for you own app design project.
    <br/>
    h3:Your daily reminder: prototyping is fast, easy, and collaborative with Uizard
    <br/>
    Tired of slow and hard to use prototyping and <a:https://uizard.io/wireframing/>UI wireframing software</a>? Uizard is here to liberate you from a world of bad design tools. Making your own notes app design for mobile has never been easier than it is with our sticky notes template. Simply sign up to Uizard Pro and bring your vision to life in minutes.
    <br/>
    h3:To do: endless customization with Uizard's premade templates
    <br/>
    Our templates are good to go straight out of the box, but if you do want to get creative, then Uizard has near-endless customization capabilities so you can really put your own branding stamp on your final design.
    "
    pages={[
      "A coloful app log-in page",
      "A sticky notes landing page for users to store all their thoughts and tasks",
      "'Add a note' user flow with all the must-have screens",
      "Voice notes mockup and note category lists to demonstrate the versatility of your app design",
    ]}
    projectCode="lrx73YWxXzUpMa1nEnqy"
    img1={data.image1.childImageSharp}
    img1alt="notes app design overview"
    img2={data.image2.childImageSharp}
    img2alt="notes app design home screen"
    img3={data.image3.childImageSharp}
    img3alt="notes app design notes detail screen"
    img4={data.image4.childImageSharp}
    img4alt="notes app design session voice notes screen"
    img5={data.image5.childImageSharp}
    img5alt="notes app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/note-taking-mobile-app/notes-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/note-taking-mobile-app/notes-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/note-taking-mobile-app/notes-app-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/note-taking-mobile-app/notes-app-voice.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/note-taking-mobile-app/notes-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
